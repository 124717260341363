import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Stepper from "./Stepper";
import { Layout, Button, Input, Row, Col, Typography, Alert, Form } from "antd";
import { GooSpinner } from "react-spinners-kit";
import axios from "axios";
import logo from "./logoCeleste3.svg";
import { motion } from "framer-motion";

const { Content } = Layout;
const { Text } = Typography;

export default function Landing() {
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const [id, setId] = useState();
  const [rate, setRate] = useState();

  const API = process.env.REACT_APP_API;

  const checkCaptcha = (value) => {
    if (value) {
      setValidCaptcha(true);
      setError(false);
    } else {
      setValidCaptcha(false);
      setError(true);
      setMessage("Verifique que no es un robot");
    }
  };

  const onFinish = async (values) => {
    setError(false);
    setMessage("");
    setData("");
    if (validCaptcha) {
      try {
        setLoading(true);
        await axios
          .get(`${API}outside?id=${values.tracking}`)
          .then((response) => {
            if (response.data.length > 0) {
              setData(response.data[0].tracking);
              console.log(response.data[0].tracking);
              setId(response.data[0]._id);
              setRate(response.data[0].rate);
              setLoading(false);
            } else {
              setLoading(false);
              setError(true);
              setMessage("Orden no existe");
            }
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
        setError(true);
        setMessage("Orden no existe");
      }
    } else {
      setError(true);
      setMessage("Verifique que no es un robot");
    }
  };

  return (
    <>
      <Layout>
        <Content align="center">
          <motion.div
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 30, opacity: 1 }}
            transition={{ delay: 0.4, type: "spring", stiffness: 120 }}
          >
            <img src={logo} alt="logo" width={250} />
          </motion.div>

          <Row>
            <Col span={24} align="center">
              <motion.div
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 70, opacity: 1 }}
                transition={{ delay: 0.8, type: "spring", stiffness: 120 }}
              >
                <Form onFinish={onFinish}>
                  <Form.Item
                    style={{ width: 300 }}
                    name="tracking"
                    hasFeedback
                    rules={[
                      { min: 24, message: "Mínimo es de 24 caracteres" },
                      { max: 24, message: "Máximo es de 24 caracteres" },
                      {
                        required: true,
                        message: "Número de orden es requerido",
                      },
                    ]}
                  >
                    <Input placeholder="Número de orden" />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Buscar
                    </Button>
                  </Form.Item>
                </Form>
              </motion.div>

              <motion.div
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 80, opacity: 1 }}
                transition={{ delay: 1.4, type: "spring", stiffness: 120 }}
              >
                <ReCAPTCHA
                  theme="dark"
                  hl="es"
                  align="center"
                  sitekey="6LfXZjUaAAAAADexuIBHh7fmo2CMZDQOKE4glsYx"
                  onChange={checkCaptcha}
                />
              </motion.div>

              {error && (
                <motion.div
                  initial={{ y: 70, opacity: 0 }}
                  animate={{ y: 100, opacity: 1 }}
                  transition={{ delay: 0.2, type: "spring", stiffness: 190 }}
                >
                  <Alert message={message} type="warning" />
                </motion.div>
              )}
            </Col>
          </Row>

          {loading ? (
            <div align="center">
              <motion.div
                initial={{ y: -10, opacity: 0 }}
                animate={{ y: 100, opacity: 1 }}
                transition={{ delay: 0.3, type: "spring", stiffness: 120 }}
              >
                <Text type="secondary">...Buscando</Text>
                <GooSpinner color="#9fc6e6" size={75} />
              </motion.div>
            </div>
          ) : data ? (
            <motion.div
              initial={{ y: 70, opacity: 0 }}
              animate={{ y: 130, opacity: 1 }}
              transition={{ delay: 0, type: "spring", stiffness: 190 }}
            >
              <Stepper value={data} id={id} rate={rate} />
            </motion.div>
          ) : null}
        </Content>
      </Layout>
    </>
  );
}
