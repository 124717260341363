import React, { useState } from "react";
import { Steps, Rate, message, Space, Typography } from "antd";
import axios from "axios";
import moment from "moment";
import { AnimatePresence, motion } from "framer-motion";

const API = process.env.REACT_APP_API;

const { Step } = Steps;

export default function Stepper({ value, id, rate }) {
  const [isRated, setIsRated] = useState(rate ? false : true);

  const handleSend = async (_value) => {
    const data = { rate: _value };
    await axios.patch(`${API}outside/${id}`, data).then((res) => {
      message.success(res.data);
      setIsRated(false);
    });
  };

  return (
    <div align="center">
      <Steps
        type="navigation"
        size="small"
        direction="vertical"
        current={JSON.parse(value.length)}
      >
        {value.map((item, i) => (
          <Step
            key={i}
            title={item.status}
            description={moment(item.date).format("LLL")}
          />
        ))}
      </Steps>
      <AnimatePresence>
        {isRated && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, y: 50 }}
          >
            <Space direction="vertical">
              <Typography.Title level={5}>
                Califica nuestro servicio
              </Typography.Title>
              <Rate allowHalf defaultValue={0} onChange={handleSend} />
            </Space>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
